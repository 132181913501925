import React, { useContext } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import QuoteSVG from "../../../../../svg/LandingPage/TestimonialCard/quote.svg";
import { isNullEmptyOrUndefined } from "../../../../utils/StringUtils";
import LandingPageImageContext from "../../../../contexts/landingPageImageContext";

/**
 * Returns either the provided rating or 1 (Default) if the rating is not a valid number.
 *
 * @param {Number} rating The number of stars to render for the card.
 */
const getWithDefault = (color) => {
  const DEFAULT_COLOR = "#f0c139";

  if (isNullEmptyOrUndefined(color)) {
    return DEFAULT_COLOR;
  }

  return color || DEFAULT_COLOR;
};

/**
 * Returns an element that renders a Featured Testimonial card based on the supplied data.
 *
 * @param {Object} props An object containing the data required to be rendered by the generated element.
 */
const TestimonialCard = (props) => {
  const { text, name, location, imageKey, imageAltText } = props.config;
  let { color } = getWithDefault(props.config);
  const landingPageImages = useContext(LandingPageImageContext);

  return (
    <div className="h-full px-2 pb-4 mx-auto lg:px-0">
      <div className={`flex flex-col flex-grow h-full my-4 rounded-md shadow-lg`} style={{ border: `solid 1px ${color}`, backgroundColor: `${color}` }}>
        <div className="flex flex-grow-0">
          <QuoteSVG className="w-10 h-10 pl-2 -mt-3 lg:-mt-2 md:w-16 md:h-12 m lg:h-16 lg:w-20" />
        </div>
        <div className="flex-grow h-full px-4 text-white">
          <p className="pt-10">{text}</p>
        </div>
        <div className="flex items-end flex-grow h-full px-4 text-white">
          <p className="w-1/2 text-xl">{name}</p>
        </div>
        <div className="flex-grow h-full px-4 text-white">
          <p className="w-1/2">{location}</p>
          <span className="flex items-start justify-end w-full pr-0 -mt-10 md:pr-4 xl:-mt-20 md:-mt-16 xl:pr-6">
            {imageKey && landingPageImages[imageKey] && (
              <Img className="flex items-start justify-end w-10 h-10 md:w-16 md:h-16 xl:w-20 xl:h-20" alt={imageAltText} fluid={landingPageImages[imageKey].childCloudinaryAsset.fluid} />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

TestimonialCard.propTypes = {
  props: PropTypes.shape({
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
  }),
};

export default TestimonialCard;
