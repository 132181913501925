import React, { useContext } from "react";
import TopDealsCopy from "../Copy";
import TopDealsCard from "../Card";
import Img from "gatsby-image";
import LandingPageImageContext from "../../../../contexts/landingPageImageContext";

/**
 * Returns an element that renders a Top Deal card based on the defined nodes.
 */
const TopDealsContainer = () => {
  const landingPageImages = useContext(LandingPageImageContext);

  return (
    <section className="container px-1 mx-auto mt-6 lg:px-8">
      <TopDealsCopy />
      <div className="flex flex-wrap -mx-px overflow-hidden sm:-mx-px md:-mx-px lg:-mx-px xl:-mx-px">
        <div className="w-1/2 px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-px lg:px-px lg:w-1/3 xl:my-px xl:px-px xl:w-1/3">
          {/* Cell 1 */}
          <TopDealsCard productCategory="Electric Rates" originalPrice="$67" tccPrice="$49.95" targetUrl="/utilities/electricity-prices-by-state">
            <Img className="rounded-t-lg" fluid={landingPageImages.electricRates.childCloudinaryAsset.fluid} />
          </TopDealsCard>
        </div>

        <div className="w-1/2 px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-1/2 md:my-px md:px-px m<d:w-1/2 lg:my-px lg:px-px lg:w-1/3 xl:my-px xl:px-px xl:w-1/3">
          {/* Cell 2 */}
          <TopDealsCard productCategory="Student Loan Refinancing" isPopular={true} originalPrice="3.5%" tccPrice="2.81%" targetUrl="/loans/refinancing-student-loans">
            <Img className="rounded-t-lg" fluid={landingPageImages.studentLoans.childCloudinaryAsset.fluid} />
          </TopDealsCard>
        </div>

        <div className="w-1/2 px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-px lg:px-px lg:w-1/3 xl:my-px xl:px-px xl:w-1/3">
          {/* Cell 3 */}
          <TopDealsCard productCategory="Renters Insurance" originalPrice="$399" tccPrice="$112" targetUrl="/insurance/renters-insurance">
            <Img className="rounded-t-lg" fluid={landingPageImages.rentersInsurance.childCloudinaryAsset.fluid} />
          </TopDealsCard>
        </div>

        <div className="w-1/2 px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-px lg:px-px lg:w-1/3 xl:my-px xl:px-px xl:w-1/3">
          {/* Cell 4 */}
          <TopDealsCard productCategory="Cell Phones" originalPrice="$1499" tccPrice="$399" targetUrl="/cell/the-best-phones">
            <Img className="rounded-t-lg" fluid={landingPageImages.cellPhones.childCloudinaryAsset.fluid} />
          </TopDealsCard>
        </div>

        <div className="w-1/2 px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-px lg:px-px lg:w-1/3 xl:my-px xl:px-px xl:w-1/3">
          {/* Cell 5 */}
          <TopDealsCard productCategory="TV Packages" originalPrice="$99" tccPrice="$34.99" targetUrl="/home/best-tv-service-providers">
            <Img className="rounded-t-lg" fluid={landingPageImages.tvPackages.childCloudinaryAsset.fluid} />
          </TopDealsCard>
        </div>

        <div className="w-1/2 px-px my-px overflow-hidden sm:my-px sm:px-px sm:w-1/2 md:my-px md:px-px md:w-1/2 lg:my-px lg:px-px lg:w-1/3 xl:my-px xl:px-px xl:w-1/3">
          {/* Cell 6 */}
          <TopDealsCard productCategory="Home Internet Deals" originalPrice="$65" tccPrice="$35" targetUrl="/home/best-internet-service-providers">
            <Img className="rounded-t-lg" fluid={landingPageImages.internetDeals.childCloudinaryAsset.fluid} />
          </TopDealsCard>
        </div>
      </div>
    </section>
  );
};

export default TopDealsContainer;
