import React from "react";

/**
 * Returns an element that renders the Landing page Hero Copy block.
 */
const HeroCopy = () => {
  return (
    <div className="flex w-full">
      <div className="inline-block w-0/12 lg:w-4/12">&nbsp;</div>
      <div className="inline-block w-full">
        <h1 className="w-full mt-4 text-4xl font-bold leading-none text-center text-white md:mt-16">One website for all your utilities</h1>
        <h2 className="w-full text-xl font-bold text-center text-yellow-landingPage700 lg:text-3xl">Our AI compares hundreds of prices so you don’t have to.</h2>
        <h2 className="w-full text-xl font-bold text-center text-yellow-landingPage700 lg:text-3xl">Save time, save money, stay private.</h2>
      </div>
    </div>
  );
};

export default HeroCopy;
