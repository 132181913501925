import { graphql, useStaticQuery } from "gatsby";

/**
 * Hook used to get all of the landing page images in a single query and return them as an object.
 *
 * Note - Each configured image query is assigned a tag which is used to access the image in the returned object.
 *
 * @returns{Object} An object where each image can be accessed by the tag associated to the image in the query.
 */
function useLandingPageImages() {
  const landingPageImageData = useStaticQuery(
    graphql`
      query {
        heroDesktop: file(name: { eq: "landingpagehero" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        heroMobile: file(name: { eq: "landingpageheroplain" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1024) {
              ...CloudinaryAssetFluid
            }
          }
        }

        electricRates: file(name: { eq: "electricrates" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        studentLoans: file(name: { eq: "studentloans" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        rentersInsurance: file(name: { eq: "rentersinsurance" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        cellPhones: file(name: { eq: "cellphones" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        tvPackages: file(name: { eq: "tvpackages" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        internetDeals: file(name: { eq: "internetdeals" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        networkSolutions: file(name: { eq: "networksolutions" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        transUnion: file(name: { eq: "transunion" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        pulsePower: file(name: { eq: "pulsepower" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        clearCover: file(name: { eq: "clearcover" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        bestow: file(name: { eq: "bestow" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        tingMobile: file(name: { eq: "ting" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        canva: file(name: { eq: "canva" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        weebly: file(name: { eq: "weebly" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        xfinity: file(name: { eq: "xfinity" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        setMeUpGroup: file(name: { eq: "setmeupgroup" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        xoomEnergy: file(name: { eq: "xoomenergy" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        creditKarma: file(name: { eq: "creditkarma" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        creditAssistanceNetwork: file(name: { eq: "creditassistancenetwork" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        kinInsurance: file(name: { eq: "kin" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        tally: file(name: { eq: "tally" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        lastPass: file(name: { eq: "lastpass" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        coinbase: file(name: { eq: "coinbase" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        handyCom: file(name: { eq: "handy" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        blinkist: file(name: { eq: "blinkist" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        lifeInsuranceInfo: file(name: { eq: "familyinsurance" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        deepSentinel: file(name: { eq: "deepsentinel" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        scoutAlarm: file(name: { eq: "scoutalarm" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        mintMobile: file(name: { eq: "mintmobile" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        carInfo: file(name: { eq: "carinsurancead" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        hrBlock: file(name: { eq: "hrblock" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        freshBooks: file(name: { eq: "freshbooks" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        loanDepot: file(name: { eq: "loandepot" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        upgrade: file(name: { eq: "upgrade" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        splash: file(name: { eq: "splash" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        boroCar: file(name: { eq: "borocar" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        saveMoneyInfo: file(name: { eq: "savemoneypiggyad" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1000) {
              ...CloudinaryAssetFluid
            }
          }
        }

        havenLife: file(name: { eq: "havenlife" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        healthIQ: file(name: { eq: "healthiq" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        constantContact: file(name: { eq: "constantcontact" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        semRush: file(name: { eq: "semrush" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        domainCom: file(name: { eq: "domain" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        rocketInc: file(name: { eq: "rocket" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        bankNovo: file(name: { eq: "novo" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        coverWallet: file(name: { eq: "coverwallet" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        corpNet: file(name: { eq: "corpnet" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
        dunBradstreet: file(name: { eq: "dunbradstreet" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        mallory: file(name: { eq: "mallory" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        travis: file(name: { eq: "travis" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }

        evelyn: file(name: { eq: "evelyn" }) {
          childCloudinaryAsset {
            fluid(maxWidth: 1920) {
              ...CloudinaryAssetFluid
            }
          }
        }
      }
    `
  );

  return landingPageImageData;
}

export default useLandingPageImages;
