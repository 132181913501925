import React, { useContext } from "react";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import LandingPageImageContext from "../../../../contexts/landingPageImageContext";
import { isNullEmptyOrUndefined } from "../../../../utils/StringUtils";

/**
 * Returns either the provided button text or "Find Out More" (Default) if the button text is null, empty of undefined.
 *
 * @param {String} buttonText The provided prop field value for buttonText.
 */
const getWithDefault = (buttonText) => {
  const DEFAULT_BUTTON_TEXT = "Find Out More";

  if (isNullEmptyOrUndefined(buttonText)) {
    return DEFAULT_BUTTON_TEXT;
  }

  return buttonText || DEFAULT_BUTTON_TEXT;
};

/**
 * Returns an element that renders a Featured Info Box card using the provided configuration data.
 *
 * @param {Object} props An object containing the data required to be rendered by the generated element.
 */
const InfoBoxCard = (props) => {
  const { buttonText, title, imageKey, imageAltText, overviewText, targetUrl, targetUrlTitle } = props.config;
  const landingPageImages = useContext(LandingPageImageContext);

  return (
    <div className="h-full px-4 py-4 m-auto hover:cursor-pointer hover:scale-110" onClick={() => navigate(targetUrl)}>
      <div className="flex flex-col h-full rounded-lg shadow-lg border-gray-landingPage600 bg-gray-landingPage100">
        {imageKey && landingPageImages[imageKey] && (
          <Img className="w-full rounded-md rounded-b-none shadow-lg h-w" alt={imageAltText} fluid={landingPageImages[imageKey].childCloudinaryAsset.fluid} />
        )}
        {!imageKey && <img className="w-full border rounded-md shadow-lg h-w" alt={imageAltText} src="https://source.unsplash.com/bGWVhFY1gH0" />}
        <p className="px-4 py-4 text-lg lg:py-6 text-blue-landingPage800">{title}</p>
        <div className="flex flex-grow">
          <p className="px-4 my-4 mr-0 text-base text-gray-landingPage800 sm:text-base md:text-base lg:text-base lg:h-20">{overviewText}</p>
        </div>
        <div className="flex flex-grow-0">
          <div className="px-4 pt-12 pb-6">
            <Link
              to={targetUrl}
              title={targetUrlTitle}
              className="px-4 py-2 text-base border rounded-md shadow-md text-gray-landingPage800 bg-yellow-landingPage800 border-yellow-landingPage800 hover:text-gray-landingPage800 hover:border-yellow-landingPage900 hover:bg-yellow-landingPage900"
            >
              {getWithDefault(buttonText)}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

InfoBoxCard.propTypes = {
  props: PropTypes.shape({
    buttonText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageKey: PropTypes.string.isRequired,
    imageAltText: PropTypes.string.isRequired,
    overviewText: PropTypes.string.isRequired,
    targetUrl: PropTypes.string.isRequired,
    targetUrlTitle: PropTypes.string.isRequired,
  }),
};

export default InfoBoxCard;
