import React from "react";

/**
 * Returns an element that renders the copy associates with the Top Deals section of the landing page.
 *
 * @returns{Element} A div containing the copy.
 */
const TopDealsCopy = () => {
  return (
    <div className="w-full mb-6 text-center text-gray-landingPage800">
      <h2 className="text-4xl font-bold">Independent Reviews</h2>
      <h3 className="px-8 text-2xl text-gray-landingPage700 lg:px-0">Side by side comparison of the best products on the market.</h3>
    </div>
  );
};

export default TopDealsCopy;
