import React, { useContext } from "react";
import { Popover } from "antd";
import WindowSizeContext from "../../../contexts/windowSizeContext";

/**
 * Returns an element that renders the Featured Deals Copy block.
 *
 * This element also renders the popover used to display the advertising disclosure.
 */
export default () => {
  const windowSize = useContext(WindowSizeContext);

  const toolTipText = (
    <div>
      <h1 className="px-2 text-lg font-bold lg:text-lg">Advertiser Disclosure</h1>
      <p className="text-base">
        We want everyone to be able to find the best deal for any utility, business, or residential. We have used, reviewed, and researched every provider on our site, which becomes increasingly hard
        as we add hundreds of prices to the site. We eventually want every provider on the market reviewed and rated by us. Bear with us as this takes time.
      </p>
      <p className="text-base">
        We can provide this service to you because we’re passionate and we may receive some compensation from the providers. The compensation may influence which plans we choose to compare and write
        about, but it does NOT affect our recommendations or advice, which are based on thousands of hours of anonymous research. Our partners cannot pay us to guarantee favorable reviews of their
        products or services.
      </p>
    </div>
  );

  return (
    <section className="container py-4 mx-auto my-4 text-center text-gray-landingPage800">
      <div className="py-4 leading-tight">
        <h2 className="text-4xl font-bold">Searching for the best rate is exhausting. We fixed that.</h2>
        <h3 className="text-2xl text-gray-landingPage700">Each day our AI searches hundreds of providers to find the best rates on utilities and bills.</h3>

        <p className="text-sm lg:text-xl">Our algorithm works alongside our Team of real-life reviewers to pick the best products and prices.</p>
        <p className="text-sm lg:text-xl">We provide comparisons of the essential services and a daily featured list.</p>

        <p className="px-4 text-sm lg:px-6 lg:text-xl">Here is our independent pick of today’s best prices and products.</p>
        <p className="pl-1 text-sm lg:text-base text-gray-landingPage800">
          <Popover className="" autoAdjustOverflow={true} trigger={windowSize.isLargeScreenView ? "hover" : "click"} title={toolTipText}>
            <span className="border border-t-0 border-l-0 border-r-0 border-gray-landingPage800 ">Advertising Disclosure</span>
          </Popover>
        </p>
      </div>
    </section>
  );
};
