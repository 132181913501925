import React, { useContext } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import LandingPageImageContext from "../../../../contexts/landingPageImageContext";
import { generateLinkButtonUsing } from "./InfoBoxAdCardLinkButtons";

/**
 * Returns an element that renders a Featured Info Ad Card based on the supplied configuration data.
 *
 * Note - The link button styling is generated in the ./InfoBoxAdCardLinkButtons module. This is required as you can not use dynamic styles (as I would want to
 * from the data configuration) as the "purge" option is set to true in the Tailwind config. This results in a much smaller production file but strips away the
 * unused styles and that includes any dynamically generated using string templates.
 *
 * @param {Object} props An object containing the data required to be rendered by the generated element.
 *
 */
const InfoBoxAdCard = (props) => {
  const { imageKey, imageAltText, targetUrl } = props.config;
  const landingPageImages = useContext(LandingPageImageContext);

  return (
    <div className="h-full px-4 py-4 m-auto transform hover:cursor-pointer hover:scale-110" onClick={() => navigate(targetUrl)}>
      <div className="h-full rounded-lg shadow-lg border-blue-landingPage600 bg-blue-landingPage100">
        <div className="relative h-full">
          {imageKey && landingPageImages[imageKey] && <Img className="w-full h-full rounded-lg" alt={imageAltText} fluid={landingPageImages[imageKey].childCloudinaryAsset.fluid} />}
          {!imageKey && <img className="w-full border rounded-md shadow-lg h-w" alt={imageAltText} src="https://source.unsplash.com/bGWVhFY1gH0" />}
          {generateLinkButtonUsing(props)}
        </div>
      </div>
    </div>
  );
};

InfoBoxAdCard.propTypes = {
  props: PropTypes.shape({
    imageKey: PropTypes.string.isRequired,
    imageAltText: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    targetUrl: PropTypes.string.isRequired,
    buttonColor: PropTypes.string,
    buttonHoverColor: PropTypes.string,
    topPositionPercentage: PropTypes.string,
    leftPositionPercentage: PropTypes.string,
  }),
};

export default InfoBoxAdCard;
