import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

/**
 * Returns an element that renders a Top Deal card based on the supplied data.
 *
 * @param {Object} props Contains the data to be used to render the card component.
 */
const TopDealsCard = (props) => {
  const { productCategory, targetUrl, isPopular, originalPrice, tccPrice, children } = props;

  return (
    <div>
      <Link to={targetUrl}>
        <div className="max-w-xl px-4 py-6 m-auto hover:cursor-pointer">
          <div className="bg-white border border-gray-100 rounded-lg shadow-lg">
            <div className="rounded-md">
              {isPopular && (
                <div className="relative top-0 left-0 z-50 w-1/2 p-2 text-sm font-bold text-center text-white border rounded-md rounded-tr-none rounded-bl-none rounded-br-none lg:w-1/3 rounded-tl-md border-green-landingPage700 bg-green-landingPage700">
                  <span>Popular</span>
                </div>
              )}
              <div className={`${isPopular ? `-mt-10` : `mt-0`}`}>{children}</div>
            </div>
            <div className="px-4 py-2 mt-0">
              <p className="h-10 px-2 my-3 mr-1 text-sm text-center text-gray-landingPage800 lg:h-8 lg:text-xl xl:text-2xl">{productCategory}</p>
              <div className="w-full py-1 text-xs lg:text-lg">
                <span className="inline-block w-full text-center text-gray-landingPage800">Find savings</span>
              </div>
              <div className="w-full py-1 text-sm lg:text-lg">
                <span className="inline-block w-1/2 text-center line-through text-red-landingPage800">{originalPrice}</span>
                <span className="inline-block w-1/2 text-center text-gray-landingPage800">{tccPrice}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

TopDealsCard.propTypes = {
  props: PropTypes.shape({
    productCategory: PropTypes.string.isRequired,
    targetUrl: PropTypes.string.isRequired,
    isPopular: PropTypes.bool.isRequired,
    originalPrice: PropTypes.string.isRequired,
    tccPrice: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  }),
};

export default TopDealsCard;
